*,
*::before,
*::after {
  box-sizing: border-box;
}
/* Font-sizing */
html {
  font-size: 16px;
  --background-color: white;
  --main-text-color: rgb(17, 17, 17);
}
@media (prefers-color-scheme: dark) {
  html {
    --background-color: #1c1e22;
    --main-text-color: white;
  }
}
/* Remove default padding */
ul,
ol {
  padding: 0;
}
/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  /* text-rendering: optimizeSpeed; */
  /* text-rendering: optimizeLegibility; */
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  font-family: sans-serif;
  background: var(--background-color);
  color: var(--main-text-color);
}
ul[class],
ol[class],
li {
  list-style: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
img {
  max-width: 100%;
  display: block;
}
input,
button,
textarea,
select {
  font: inherit;
}
a {
  text-decoration: none;
  color: var(--main-text-color);
}
